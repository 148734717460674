import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { InternalAPI } from 'src/app/services/utils/client-api';
import { PermissionManagerService } from 'src/app/services/utils/permission-manager.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../services/utils/client-api";
import * as i3 from "../../../services/utils/permission-manager.service";
var NewService = /** @class */ (function () {
    function NewService(http, api, permissionManager) {
        this.http = http;
        this.api = api;
        this.permissionManager = permissionManager;
        this.newUrl = 'UI/APINews/Load';
    }
    NewService.prototype.getNews = function () {
        var portalAuth = JSON.parse(localStorage.getItem("portalAuth"));
        var request = {
            Token: portalAuth.Token,
            UserName: portalAuth.UserName,
        };
        return this.http.post(this.newUrl, request)
            .pipe(map(function (response) { return response.Records; }));
    };
    NewService.prototype.getNewsByAgency = function (onError) {
        return this.getNews();
    };
    NewService.prototype.setBody = function (AgencyInformation) {
        return {
            AgencyId: AgencyInformation.AgencyId,
            CompanyId: AgencyInformation.CompanyId,
            States: this.getStatesFromLobs(AgencyInformation.AgencyLobs)
        };
    };
    NewService.prototype.getStatesFromLobs = function (lobs) {
        var states = [];
        var uniqueStateLobs = lobs.filter(function (value, index, state) { return state.indexOf(value) === index; });
        for (var i = 0; i < uniqueStateLobs.length; i++) {
            states.push(uniqueStateLobs[i].StateId);
        }
        return states;
    };
    NewService.ngInjectableDef = i0.defineInjectable({ factory: function NewService_Factory() { return new NewService(i0.inject(i1.HttpClient), i0.inject(i2.InternalAPI), i0.inject(i3.PermissionManagerService)); }, token: NewService, providedIn: "root" });
    return NewService;
}());
export { NewService };
