import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpOptions } from './HttpOptions';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ResponseDto, LoginResponseDto } from '../response-dto';
import { GenericRequest } from './GenericRequest';
import { CredentialsService } from './credentials.service';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class InternalAPI {

  genericServiceUrl = 'ui/GenericService';
  genericServiceHttpResponseUrl = 'ui/GenericServiceHttpResponse';

  constructor(private http: HttpClient,
    private httpOps: HttpOptions,
    private notifierService: NotifierService,
    private credentialsService: CredentialsService) {
  }

  public getAuthorizationHeader(): any {

    const token = this.credentialsService.GetAuthData();

    const headers = token ? { authorization: `Basic ${token}` } : {};

    return headers;
  }

  public post<T>(url: string, body: any, onError: () => void = null): Observable<T> {
    if (body === null) {
      body = {};
    }

    const request: GenericRequest = {
      body: JSON.stringify(body),
      httpMethod: 'POST',
      uri: url,
      headers: this.getAuthorizationHeader()
    };

    return this.http.post<T>(this.genericServiceUrl, request, this.httpOps.getDefault())
      .pipe(
        tap((t: any) => {
          this.checkStatus(t, onError);
        })
      );
  }

  public get<T>(url: string, onError: () => void = null): Observable<T> {
    const request: GenericRequest = {
      body: null,
      httpMethod: 'GET',
      uri: url,
      headers: this.getAuthorizationHeader()
    };

    return this.http.post<any>(this.genericServiceUrl, request, this.httpOps.getDefault())
      .pipe(
        tap((t: any) => {
          this.checkStatus(t, onError);
        })
      );
  }

  public httpResponse<T>(url: string, onError: () => void = null, body: any): Observable<T> {

    const request: GenericRequest = {
      body: body ? JSON.stringify(body) : null,
      httpMethod: body ? 'POST' : 'GET',
      uri: url,
      headers: this.getAuthorizationHeader()
    };

    return this.http.post<any>(this.genericServiceHttpResponseUrl, request)
      .pipe(
        tap((t: any) => {
          this.checkStatusHttp(t, onError);
        })
      );
  }

  public directPost<T>(url: string, body: any, onError: () => void = null): Observable<T> {
    if (body === null) {
      body = {};
    }

    return this.http.post<T>(url, body, this.httpOps.getDefault())
      .pipe(
        tap((t: any) => {
          this.checkStatus(t, onError);
        })
      );
  }

  public directGet<T>(url: string, onError: () => void = null): Observable<T> {
    return this.http.post<T>(url, this.httpOps.getDefault())
      .pipe(
        tap((t: any) => {
          this.checkStatus(t, onError);
        })
      );
  }

  private genericMessage = (message: string, isError: boolean) => {
    if (message && message.length) {
      if (isError) {
        this.notifierService.notify('error', message);
      } else {
        if (message !== '(R-1803) Invalid term entered. Semi-Annual rates shown.') {
          this.notifierService.notify('warning', message);
        }
      }
    }
  }

  private checkStatusHttp = (t: any, onError: any) => {
    if (t.StatusCode !== 200) {
      this.genericMessage(t.Message, true);
    }

    if (t.StatusCode !== 200 && onError) {
      onError();
    }
  }

  public checkStatus = (t: ResponseDto<any>, onError: any) => {
    this.genericMessage(t.ErrorMessage, true);
    this.genericMessage(t.WarningMessage, false);

    if (!t.Sucess && t.DiamondErrors && t.DiamondErrors.length) {
      t.DiamondErrors.forEach(element => {
        this.genericMessage(element.replace(/<[^>]*>/g, ''), true);
      });
    }

    if (!t.Sucess && onError != null) {
      onError();
    }

    if (t.Sucess && t.Records) {

      // The HasWarnigErrors property is badly type because comes from back-end in that way
      // the correct name is HasWarningErrors with ning
      if (t.Records.length > 0 && t.Records[0] && (t.Records[0].HasErrors || t.Records[0].HasWarnigErrors)) {

        if (t.Records[0].DiamondErrors && t.Records[0].DiamondErrors.length) {
          t.Records[0].DiamondErrors.forEach(element => {
            this.genericMessage(element.replace(/<[^>]*>/g, '\n'), true);
          });
        }

        if (t.Records[0].DiamondWarnings && t.Records[0].DiamondWarnings.length) {
          t.Records[0].DiamondWarnings.forEach(element => {
            this.genericMessage(element.replace(/<[^>]*>/g, '\n'), false);
          });
        }
      }
    }
  }
}
