import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(userService, router, location) {
        this.userService = userService;
        this.router = router;
        this.location = location;
        this.bridged = false;
    }
    AuthGuard.prototype.canActivate = function () {
        if (location.search === "?bridging=true") {
            environment.authRequired = true;
        } //for bridging we always need auth required
        if (environment.authRequired) {
            if (location.search == "?bridging=true") {
                localStorage.removeItem("authData");
                var autoBridgingParams = window.location.href.split("/").pop().split("?");
                var policyNumber = autoBridgingParams[0];
                this.userService.autoBridge(policyNumber).subscribe();
            }
            else {
                if (!this.userService.isAuthenticated()) {
                    this.router.navigate(['/sign-in']);
                }
                return this.userService.isAuthenticated();
            }
        }
        else {
            return true; //only for debug and dev, see environment to change flag "authRequired"
        }
    };
    AuthGuard.prototype.canActivateChild = function () {
        return this.canActivate();
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AuthService), i0.inject(i2.Router), i0.inject(i3.Location)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
