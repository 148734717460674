<div class="register-form">
  <img src="scripts/assets/logo.png" height="60px">
  <h4>Register for New Account</h4>
  <form>
    <div class="form-group">
      <input class="form-control" name="username" placeholder="Requested User Name" />
    </div>
    <div class="form-group">
      <input class="form-control" name="email" placeholder="Email" type="email">
    </div>
    <div class="form-group">
      <input class="form-control" name="password" placeholder="Password" type="password">
    </div>
    <div class="form-group">
      <input class="form-control" name="password-again" placeholder="Password again" type="password">
    </div>
    <button class="btn btn-default" routerLink="/sign-in" routerLinkActive="active">Cancel</button>
    <button class="btn btn-primary">Register</button>
  </form>
</div>