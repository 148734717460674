import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private userService: AuthService,
    private router: Router,
    private location: Location
  ) { }

  private bridged: Boolean = false;

  canActivate(): boolean {
    if (location.search === "?bridging=true") { environment.authRequired = true; } //for bridging we always need auth required
    if (environment.authRequired) {
      if (location.search == "?bridging=true") {

        localStorage.removeItem("authData");
        var autoBridgingParams: string[] = window.location.href.split("/").pop().split("?");
        var policyNumber: string = autoBridgingParams[0];

        this.userService.autoBridge(policyNumber).subscribe();
      }
      else {
        if (!this.userService.isAuthenticated()) {
          this.router.navigate(['/sign-in']);
        }

        return this.userService.isAuthenticated();
      }


    } else {
      return true; //only for debug and dev, see environment to change flag "authRequired"
    }
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }
}
