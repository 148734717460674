import { BaseAddress } from "../../util/models/Address";
export class PSRequest {
  UserId: string;
  UserName: string;
  Password: string;
  Environment: string;
  Token: string;
  IsTest: boolean;
  PolicyNumber: string;
  FirstName: string;
  LastName: string;
  StreetAddress: BaseAddress = new BaseAddress();
  LicenseNumber: string;
  LicenseState: string;
  VIN : string;
  Email : string;
  Phone : string;
  Status : string;
  Agency : string;
  Quotes: boolean;
  Policies: boolean;

}
