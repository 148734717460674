import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './root-component/app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FwModule } from 'src/fw/fw.module';
import { AuthService } from './services/auth/auth.service';
import { UserApi } from 'src/fw/users/user-api';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserIdleModule } from 'angular-user-idle';
import { environment } from 'src/environments/environment';
import { NotifierModule } from 'angular-notifier';
import { VisitorsService } from './services/utils/visitors.service';
import { GuideLinesComponent } from './guide-lines/guide-lines.component';
import {FlexModule} from "@angular/flex-layout";
import { DialogOverviewExampleDialogComponent } from './dialog-overview-example-dialog/dialog-overview-example-dialog.component';
import { CacheInterceptor } from './interceptors/CacheInterceptor';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    DialogOverviewExampleDialogComponent,
    GuideLinesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FwModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
          distance: 12
        },
        vertical: {
          position: 'bottom',
          distance: 12,
          gap: 10
        }
      },
      behaviour: {
        autoHide: 3600000 * 6, // 6 hour
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 10
      },
    }),
    UserIdleModule.forRoot({
      idle: environment.idleMinutes * 60, // params in seconds
      timeout: environment.timeoutMinutes * 60, // params in seconds
      ping: environment.pingSeconds
    }),
    FlexModule,
  ],
  providers: [
    AuthService,
    { provide: UserApi, useExisting: AuthService },
      VisitorsService, 
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
  ],
  entryComponents: [DialogOverviewExampleDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
