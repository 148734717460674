import { Injectable } from '@angular/core';
import { InternalAPI } from './client-api';
import { VersionData } from '../dtos/version-data-dto';
import { ResponseDto } from '../response-dto';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { SystemVersionDataRequest } from '../models/SystemVersionDataRequest';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { LoginResponse } from '../../backoffice/policysearch/models/loginresponse';


@Injectable({
  providedIn: 'root'
})
export class VersionDataService {

  versionURL = "UI/APIVersionData";
  systemURL = "UI/APISystemData";

  url = "Quote/GetVersionData/{userId}/{versionId}";
  private vdSubject = new Subject<VersionData>();

  constructor(private internalApi: InternalAPI, private http: HttpClient) {
  }

  public loadSystemData(environment: string): Observable<any> {
    var systemJson: string = localStorage.getItem("systemData");
    let systemData = JSON.parse(systemJson);

    if (systemData) {
      return of(systemData);
    }

    let systemDataRequest = new SystemVersionDataRequest();
    systemDataRequest.Environment = environment;
    return this.http.post<any>(this.systemURL, systemDataRequest)
      .pipe(map(userResponse => {
        if (userResponse.ResponseStatus.StatusCode === 200) {
          localStorage.setItem("systemData", JSON.stringify(userResponse));
          return userResponse;
        }
        else {
          return of(null);
        }
      }));
  }

  public loadVersionData(versionId: number): Observable<any> {

    var versionJson: string = localStorage.getItem("versionId" + versionId);
    let versionData = JSON.parse(versionJson);

    if (versionData) {
      return of(versionData);
    }

    var creds: string = localStorage.getItem("user");
    let loginResponse: LoginResponse = JSON.parse(creds);
    let versionDataRequest = new SystemVersionDataRequest();
    versionDataRequest.Environment = localStorage.getItem("environment");
    versionDataRequest.Token = loginResponse.Token;
    versionDataRequest.UserName = "ServiceAccount@bluefireins.com";
    versionDataRequest.UserId = loginResponse.DisplayName;
    versionDataRequest.Password = localStorage.getItem("p");
    versionDataRequest.VersionId = versionId;

    return this.http.post<any>(this.versionURL, versionDataRequest)
      .pipe(map(userResponse => {
        if (userResponse.ResponseStatus.StatusCode === 200) {
          localStorage.setItem("versionId" + versionId, JSON.stringify(userResponse));
          return userResponse;
        }
        else {
          return of(null);
        }
      }));
  }

  public getVersionDataId(): number {
    return this.getVersionData().VersionId;
  }

  public getVersionData(): VersionData {
    return JSON.parse(localStorage.getItem('versionData'));
  }

  public subscribe(handler: (versionData: VersionData) => void): Subscription {
    return this.vdSubject.subscribe(handler);
  }

}
