import { Injectable } from '@angular/core';
import { New } from '../dtos/new-dto';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { InternalAPI } from 'src/app/services/utils/client-api';
import { ResponseDto } from 'src/app/services/response-dto';
import { PermissionManagerService } from 'src/app/services/utils/permission-manager.service';

@Injectable({
  providedIn: 'root'
})
export class NewService {

  private newUrl = 'UI/APINews/Load';

  constructor(private http: HttpClient,private api: InternalAPI, private permissionManager: PermissionManagerService) {
  }

  public getNews(): Observable<New[]> {
    let portalAuth = JSON.parse(localStorage.getItem("portalAuth"));

    let request = {
      Token: portalAuth.Token,
      UserName: portalAuth.UserName,
    };

    return this.http.post<any>(this.newUrl, request)
      .pipe(
        map((response: ResponseDto<New>) => response.Records)
      );
  }

  public getNewsByAgency(onError) {
    return this.getNews();
  }

  setBody(AgencyInformation) {
    return {
      AgencyId: AgencyInformation.AgencyId,
      CompanyId: AgencyInformation.CompanyId,
      States: this.getStatesFromLobs(AgencyInformation.AgencyLobs)
    };
  }

  getStatesFromLobs(lobs) {
    let states = [];
    let uniqueStateLobs = lobs.filter((value, index, state) => state.indexOf(value) === index);
    for (let i = 0; i < uniqueStateLobs.length; i ++) {
        states.push(uniqueStateLobs[i].StateId);
    }
    return states;
}

}
