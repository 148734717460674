import * as i0 from "@angular/core";
var FrameworkConfigService = /** @class */ (function () {
    function FrameworkConfigService() {
        this.showLanguagesSelector = true;
        this.showUserControls = true;
        this.showStatusBar = true;
        this.showStatusBarBreakpoint = 0;
        this.socialIcons = new Array();
    }
    FrameworkConfigService.prototype.configure = function (setting) {
        Object.assign(this, setting);
    };
    FrameworkConfigService.ngInjectableDef = i0.defineInjectable({ factory: function FrameworkConfigService_Factory() { return new FrameworkConfigService(); }, token: FrameworkConfigService, providedIn: "root" });
    return FrameworkConfigService;
}());
export { FrameworkConfigService };
