import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SignInComponent } from 'src/fw/users/sign-in/sign-in.component';
import { RegisterUserComponent } from 'src/fw/users/register-user/register-user.component';
import { AuthGuard } from './services/auth/auth-guard.service';
import { GuideLinesComponent } from './guide-lines/guide-lines.component';

const routes: Routes = [
    { path: 'guidelines', component: GuideLinesComponent },
    { path: 'sign-in', component: SignInComponent },
    { path: 'register', component: RegisterUserComponent },
    {
        path: 'backoffice',
        loadChildren: './backoffice/backoffice.module#BackofficeModule',
        canActivate: [AuthGuard]
    },
    { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
