<mat-card class="screen login">
  <mat-card-content>
    <!--<img src="scripts/assets/sapphirelogo60.png" height="60px" style="padding-bottom:15px">-->
    <form class="container" [formGroup]="fg" (ngSubmit)="onSubmit(fg)">
      <mat-form-field>
        <mat-label>Systems</mat-label>
        <mat-select formControlName="system">
          <mat-option *ngFor="let item of systems" [value]="item.Value">{{item.Description}}</mat-option>
          <!--<mat-option [value]="42">nIgnite</mat-option>
          <mat-option [value]="81">Ignite</mat-option>-->
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input matInput required placeholder="User Name" formControlName="username">
        <mat-error *ngIf="fg.get('username').hasError('required')">User name is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput required placeholder="Enter your password" [type]="hide ? 'password' : 'text'"
               formControlName="password" (change)="clearMessage()">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="fg.get('password').hasError('required')">Password is required</mat-error>
      </mat-form-field>
      <h3>{{message}}</h3>
      <div class="btn-container" *ngIf="!isLoading()">
        <button mat-raised-button class="floatClass" type="submit">SIGN IN</button>
      </div>
      <mat-progress-bar *ngIf="isLoading()" mode="indeterminate"></mat-progress-bar>
    </form>
  </mat-card-content>
</mat-card>
