import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var PortalAuthService = /** @class */ (function () {
    function PortalAuthService(http, router) {
        this.http = http;
        this.router = router;
        this.postUrl = "/UI/APIPortalAuth";
    }
    PortalAuthService.prototype.auth = function (psRequest) {
        var DeserializeString = JSON.stringify(psRequest);
        return this.http.post(this.postUrl, { DeserializeString: DeserializeString })
            .pipe(map(function (userResponse) {
            // login successful if there's a jwt token in the response
            if (userResponse.ResponseStatus.StatusCode === 200) {
                localStorage.setItem('portalAuth', JSON.stringify(userResponse));
                localStorage.setItem('environment', psRequest.Environment);
            }
            return userResponse;
        }));
    };
    PortalAuthService.ngInjectableDef = i0.defineInjectable({ factory: function PortalAuthService_Factory() { return new PortalAuthService(i0.inject(i1.HttpClient), i0.inject(i2.Router)); }, token: PortalAuthService, providedIn: "root" });
    return PortalAuthService;
}());
export { PortalAuthService };
