import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PSRequest } from "../../backoffice/policysearch/models/Request";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";


@Injectable({ providedIn: 'root' })
export class PortalAuthService {
  postUrl: string = "/UI/APIPortalAuth";
  postData: string;

  constructor(private http: HttpClient, private router: Router) { }

  auth<T>(psRequest: PSRequest): Observable<T> {
    var DeserializeString : string = JSON.stringify(psRequest);
    return this.http.post<any>(this.postUrl, { DeserializeString } )
    .pipe(map(userResponse => {
      // login successful if there's a jwt token in the response
      if (userResponse.ResponseStatus.StatusCode === 200) {
        localStorage.setItem('portalAuth', JSON.stringify(userResponse));
        localStorage.setItem('environment', psRequest.Environment);
      }
      return userResponse;
    }));
  }
}

