import * as i0 from "@angular/core";
var MenuService = /** @class */ (function () {
    function MenuService() {
        this.showLeftSideMenu = false;
    }
    MenuService.prototype.toggleLeftSideMenu = function () {
        this.showLeftSideMenu = !this.showLeftSideMenu;
    };
    MenuService.ngInjectableDef = i0.defineInjectable({ factory: function MenuService_Factory() { return new MenuService(); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
