/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page-not-found.component";
import * as i3 from "@angular/common";
var styles_PageNotFoundComponent = [i0.styles];
var RenderType_PageNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Page not found"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Go Back"]))], null, null); }
export function View_PageNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i2.PageNotFoundComponent, [i3.Location], null, null)], null, null); }
var PageNotFoundComponentNgFactory = i1.ɵccf("app-page-not-found", i2.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
