import * as tslib_1 from "tslib";
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { InternalAPI } from '../utils/client-api';
import { LoginRequest } from './dtos';
import { VersionDataService } from '../utils/version-data.service';
import { PermissionManagerService } from '../utils/permission-manager.service';
import { NewService } from 'src/app/backoffice/news/services/new.service';
import { CredentialsService } from '../utils/credentials.service';
import { HttpClient } from '@angular/common/http';
import { HttpOptions } from '../utils/HttpOptions';
import { NotifierService } from 'angular-notifier';
import { PortalAuthService } from "./portal-auth.service";
import { PSRequest } from "../../backoffice/policysearch/models/Request";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../utils/client-api";
import * as i3 from "../utils/version-data.service";
import * as i4 from "../utils/permission-manager.service";
import * as i5 from "../../backoffice/news/services/new.service";
import * as i6 from "../utils/credentials.service";
import * as i7 from "angular-notifier";
import * as i8 from "@angular/common/http";
import * as i9 from "../utils/HttpOptions";
import * as i10 from "./portal-auth.service";
var AuthService = /** @class */ (function () {
    function AuthService(router, internalApi, versionDataService, permissionManager, newService, credentialsService, notifierService, http, httpOps, portalAuthService) {
        this.router = router;
        this.internalApi = internalApi;
        this.versionDataService = versionDataService;
        this.permissionManager = permissionManager;
        this.newService = newService;
        this.credentialsService = credentialsService;
        this.notifierService = notifierService;
        this.http = http;
        this.httpOps = httpOps;
        this.portalAuthService = portalAuthService;
        this.url = "authentication";
        this.autoBridgingUrl = "bridging/GetCredentials/";
        this.domainUrl = "DomainAuthenticate";
        this.loginServiceUrl = 'login/Login';
        this.autoBridgingServiceUrl = 'UI/AutoBridge';
        this.PSRequest = new PSRequest();
        this.loading = false;
    }
    AuthService.prototype.isLoading = function () {
        return this.loading;
    };
    AuthService.prototype.isAuthenticated = function () {
        var authData = localStorage.getItem("authData");
        if (authData && authData.length > 0) {
            return true;
        }
        return false;
    };
    AuthService.prototype.getSystems = function () {
        return this.http.get("UI/APIGetSystemsList")
            .pipe(map(function (userResponse) {
            return userResponse;
        }));
    };
    AuthService.prototype.signIn = function (username, password, system, rememberMe) {
        var _this = this;
        this.loading = true;
        this.clearLocalStorage();
        this.credentialsService.ClearHeaderLocalStorage();
        var body = {
            Name: username,
            Password: password,
            BridgeQuote: null,
            BridgeQuoteNumber: null
        };
        var onError = function () { _this.loading = false; };
        this.PSRequest.UserName = username;
        this.PSRequest.UserId = username;
        this.PSRequest.Password = password;
        this.PSRequest.Environment = "C" + system;
        return forkJoin(this.versionDataService.loadSystemData(this.PSRequest.Environment), this.portalAuthService.auth(this.PSRequest)).pipe(map(function (_a) {
            var sysData = _a[0], auth = _a[1];
            _this.loginSuccess(auth, body);
            return auth.ResponseStatus.StatusCode === 200;
        }));
    };
    AuthService.prototype.signout = function () {
        this.clearLocalStorage();
        this.loading = false;
        this.router.navigate(["/sign-in"]);
        return of({});
    };
    AuthService.prototype.clearLocalStorage = function () {
        localStorage.clear();
    };
    AuthService.prototype.loginSuccess = function (r, credentials) {
        if (r.ResponseStatus.StatusCode === 200) {
            if (r.SystemVersion.ErrorMessage !== '') {
                localStorage.setItem('VersionNumber', '0');
            }
            else {
                localStorage.setItem('VersionNumber', r.SystemVersion.VersionNumber);
            }
            localStorage.setItem('BillMethodId', r.BillMethodId);
            r.DisplayName = r.UserName;
            r.UserId = r.DiamondUserId;
            this.permissionManager.setCurrentUser(r);
            this.credentialsService.SetAuthData(credentials);
        }
        else {
            this.loading = false;
        }
    };
    AuthService.prototype.isNewsAvailable = function () {
        var _this = this;
        var onError = function () { _this.loading = false; };
        this.newService.getNewsByAgency(onError).subscribe(function (res) {
            if (res && res.length !== 0) {
                return true;
            }
        });
        return false;
    };
    AuthService.prototype.getVersionNumber = function () {
        return localStorage.getItem('VersionNumber');
    };
    AuthService.prototype.autoBridge = function (policynumber) {
        var _this = this;
        this.credentialsService.ClearHeaderLocalStorage();
        var onError = function () { _this.loading = false; };
        var tempEnvironment = "C42";
        var autoBridge = this.http.post(this.autoBridgingServiceUrl, { Token: policynumber, Environment: tempEnvironment }).pipe(map(function (userResponse) {
            // login successful if there's a jwt token in the response
            if (userResponse.ResponseStatus.StatusCode === 200) {
                localStorage.setItem('portalAuth', JSON.stringify(userResponse));
                localStorage.setItem('environment', tempEnvironment);
            }
            return userResponse;
        }));
        return forkJoin(this.versionDataService.loadSystemData(tempEnvironment), autoBridge)
            .pipe(map(function (_a) {
            var sysData = _a[0], auth = _a[1];
            var body = {
                Name: auth.UserName,
                Password: "",
                BridgeQuote: null,
                BridgeQuoteNumber: null
            };
            _this.loginSuccess(auth, body);
            window.location.href += 'backoffice/quotes/quote-capture/' + policynumber + '/bridge';
            return true;
        }));
    };
    AuthService.prototype.delay = function (ms) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return setTimeout(resolve, ms); })];
            });
        });
    };
    AuthService.prototype.getBridgingCredentials = function (url, onError) {
        if (onError === void 0) { onError = null; }
        var request = {
            body: null,
            httpMethod: "GET",
            uri: url,
            headers: this.internalApi.getAuthorizationHeader()
        };
        return this.http.post(this.autoBridgingServiceUrl, request)
            .pipe();
    };
    AuthService.prototype.isAutoBridged = function () {
        var autoBridgeData = localStorage.getItem("AutoBridged");
        if (autoBridgeData && autoBridgeData == "1") {
            return true;
        }
        return false;
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.Router), i0.inject(i2.InternalAPI), i0.inject(i3.VersionDataService), i0.inject(i4.PermissionManagerService), i0.inject(i5.NewService), i0.inject(i6.CredentialsService), i0.inject(i7.NotifierService), i0.inject(i8.HttpClient), i0.inject(i9.HttpOptions), i0.inject(i10.PortalAuthService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
var GetSystemsList = /** @class */ (function () {
    function GetSystemsList() {
    }
    return GetSystemsList;
}());
export { GetSystemsList };
