import { Injectable } from '@angular/core';
import { User } from '../auth/dtos';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  constructor() { }

  private authDataKey = "authData";

  SetAuthData(credentials) {
    let authData = this.EncodeToBase64(credentials.Name + ':' + credentials.Password + ':' + 'undefined');
    localStorage.setItem(this.authDataKey, JSON.stringify(authData));
  }

  GetAuthData() {
    return JSON.parse(localStorage.getItem(this.authDataKey));
  }

  ClearHeaderLocalStorage() {
    localStorage.removeItem(this.authDataKey);
  }

  private EncodeToBase64(input) {
    const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = "";
    let chr1: any = "";
    let chr2: any = "";
    let chr3: any = "";
    let enc1: any = "";
    let enc2: any = "";
    let enc3: any = "";
    let enc4: any = "";
    let i = 0;

    do {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output = output +
        keyStr.charAt(enc1) +
        keyStr.charAt(enc2) +
        keyStr.charAt(enc3) +
        keyStr.charAt(enc4);
      chr1 = chr2 = chr3 = "";
      enc1 = enc2 = enc3 = enc4 = "";
    } while (i < input.length);

    return output;
  }

}
