import { Observable } from 'rxjs';

export abstract class UserApi {

  signIn: (username: string, password: string,system: string, rememberMe: boolean) => Observable<boolean>;
  signout: () => Observable<any>;

  isLoading: () => boolean;
  isAuthenticated: () => boolean;

  isNewsAvailable: () => boolean;

  autoBridge: (policynumber: string) => Observable<boolean>;
}
