import { Injectable } from '@angular/core';

export interface MenuItem {
    text: string,
    icon: string,
    route: string,
    submenu: Array<MenuItem>
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    items: Array<MenuItem>;
    showLeftSideMenu = false;

    toggleLeftSideMenu(): void {
        this.showLeftSideMenu = !this.showLeftSideMenu;
    }

}