<mat-card class="continer">

  <!--Headings-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Headings
      </mat-panel-title>
    </mat-expansion-panel-header>
    <h1>H1</h1>
    <h2>H2</h2>
    <h3>H3</h3>
    <h4>H4</h4>
    <h5>H5</h5>
    <h6>H6</h6>
  </mat-expansion-panel>

  <!--Colors palette-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Colors palette
      </mat-panel-title>
    </mat-expansion-panel-header>


    <div style="width: 12%; display: inline-block;">
      <div class="mat-secondary" style="height: 50px; width: 50px;"></div>
      <span>#71caf7 - Secondary</span>
    </div>
    <div style="width: 12%; display: inline-block;">
      <div class="mat-primary-extended" style="height: 50px; width: 50px;"></div>
      <span>#0069a6 - Primary</span>
    </div>

    <div style="width: 12%; display: inline-block;">
      <div class="searchButton" style="height: 50px; width: 50px;"></div>
      <span>#fff</span>
    </div>

    <div style="width: 12%; display: inline-block;">
      <div style="height: 50px; width: 50px; background-color: rgba(0,0,0,.87);"></div>
      <span>rgba(0,0,0,.87)</span>
    </div>

    <div style="width: 12%; display: inline-block;">
      <div class="notifier__notification--error" style="height: 50px; width: 50px;"></div>
      <span>#D9534F - Error</span>
    </div>


    <div style="width: 12%; display: inline-block;">
      <div class="notifier__notification--warning" style="height: 50px; width: 50px;"></div>
      <span>#F0AD4E - Warning</span>
    </div>

    <div style="width: 12%; display: inline-block;">
      <div style="height: 50px; width: 50px; background-color: #3f51b5;"></div>
      <span>#3f51b5 - Step</span>
    </div>

    <div style="width: 12%; display: inline-block;">
      <div style="height: 50px; width: 50px; background-color: rgba(0,0,0,.54);"></div>
      <span>rgba(0,0,0,.54) - Step disable</span>
    </div>
  </mat-expansion-panel>

  <!--Buttons-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Buttons
      </mat-panel-title>
    </mat-expansion-panel-header>
    <button mat-raised-button>Click me!</button>

    <button mat-raised-button class="mat-primary-extended" type="submit">Click me!</button>

    <button mat-raised-button type="button" color="secondary">Click me!</button>

    <br>

    <br>

    <button mat-raised-button>
      <i class="material-icons" style="vertical-align: middle">book</i>
      Click me!
    </button>

    <button mat-raised-button class="mat-primary-extended" type="submit">
      <i class="material-icons" style="vertical-align: middle">book</i>
      Click me!
    </button>

    <button mat-raised-button type="button" color="secondary">
      <i class="material-icons" style="vertical-align: middle">book</i>
      Click me!
    </button>

    <br>
    <br>

    <button type="button" class="floatButton mat-primary-extended" mat-fab data-hover="Create ">
      <mat-icon>create</mat-icon>
    </button>

    <br>
    <br>

    <button type="button" class="floatButton mat-primary-extended" [disabled]="true" mat-fab data-hover="Create ">
      <mat-icon>create</mat-icon>
    </button>

    <br>
    <br>

    <div>

      <div class="accident-name" fxLayoutAlign="space-around center">
        <div fxFlex="calc(10%-5px)">
          <mat-icon id="deleteIcon" type="button">
            delete_forever
          </mat-icon>
        </div>
      </div>

    </div>
  </mat-expansion-panel>

  <!--Inputs-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Inputs
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign.gt-sm="start start">
      <mat-card fxFlex="calc(100%-20px)">
        <mat-card-title>
          Example #1
        </mat-card-title>
        <mat-card-content fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">
          
          <mat-form-field fxFlex="calc(15%-20px)">
            <input matInput placeholder="Phone Number" name="phone">
            <mat-icon matSuffix>phone</mat-icon>
          </mat-form-field>

          <mat-form-field fxFlex="calc(15%-20px)">
            <input matInput placeholder="First Name" name="firstName" required maxlength="30">
          </mat-form-field>

          <mat-form-field fxFlex="calc(15%-20px)">
            <input matInput placeholder="Primary E-mail" name="email" email
                  maxlength="50">
            <mat-icon matSuffix>email</mat-icon>
          </mat-form-field>

          <mat-form-field fxFlex="calc(15%-20px)">
            <input placeholder="Date of Birth" matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex="calc(15%-20px)">
            <mat-select required placeholder="Select Term" name="policyTerm">
              <mat-option *ngFor="let food of foods" [value]="food.value">
                {{food.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error>Please select one option</mat-error>
          </mat-form-field>

          <mat-form-field>
            <input matInput value="$34.00" type="text" placeholder="Amount" [required]="true" />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>

  </mat-expansion-panel>

  <!--Labels-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Labels
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="font-size: 24px; font-weight: 500">{{ 123.12 | currency}} Down</div>
    <div class="titleNumber">
      <p>{{ 123.12 | currency }} Full Term</p>
    </div>
  </mat-expansion-panel>

  <!--Radio button-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Radio button
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-radio-group fxLayout="row" fxLayoutGap="5px">
      <mat-radio-button [checked]="true" [value]="2">Radiobutton 1</mat-radio-button>
      <mat-radio-button [value]="1">Radiobutton 2</mat-radio-button>
    </mat-radio-group>
  </mat-expansion-panel>

  <!--Checkbox-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Checkbox
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-checkbox>Check me!</mat-checkbox>
  </mat-expansion-panel>

  <!--Toogle-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Toggle
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-slide-toggle>Slide me!</mat-slide-toggle>
  </mat-expansion-panel>

  <!--Progress bar-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Progress bar
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-expansion-panel>

  <!--Tabs-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Tabs
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-tab-group>
      <mat-tab label="One">
        <h1>Some tab content</h1>
        <p>...</p>
      </mat-tab>
      <mat-tab label="Two">
        <h1>Some more tab content</h1>
        <p>...</p>
      </mat-tab>
    </mat-tab-group>
  </mat-expansion-panel>

  <!--Cards-->
  <mat-expansion-panel class="backgroundColor">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Cards
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign.gt-sm="start start">
      <mat-card fxFlex="calc(100%-20px)">
        <mat-card-title>
          Example #1
        </mat-card-title>
        <mat-card-content fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">
          <h1>Some more tab content</h1>
        </mat-card-content>
      </mat-card>
    </div>

    <br>
    <br>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign.gt-sm="start start">
      <mat-card fxFlex="calc(50%-20px)">
        <mat-card-title>
          Example #2
        </mat-card-title>
        <mat-card-content fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">
          <h1>Some more tab content</h1>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="calc(50%-20px)">
        <mat-card-title>
          Example #2
        </mat-card-title>
        <mat-card-content fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">
          <h1>Some more tab content</h1>
        </mat-card-content>
      </mat-card>
    </div>

    <br>
    <br>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign.gt-sm="start start">
      <mat-card fxFlex="calc(30%-20px)">
        <mat-card-title>
          Example #3
        </mat-card-title>
        <mat-card-content fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">
          <h1>Some more tab content</h1>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="calc(30%-20px)">
        <mat-card-title>
          Example #3
        </mat-card-title>
        <mat-card-content fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">
          <h1>Some more tab content</h1>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="calc(30%-20px)">
        <mat-card-title>
          Example #3
        </mat-card-title>
        <mat-card-content fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">
          <h1>Some more tab content</h1>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-expansion-panel>

  <!--Stepper-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Stepper
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-horizontal-stepper [@.disabled]="true" class="new-features" linear #stepper labelPosition="bottom">
      <mat-step label="Applicant info" state="time_to_leave">
        Step 1
      </mat-step>
      <mat-step label="Drivers" state="time_to_leave">
        Step 2
      </mat-step>
      <mat-step label="Vehicles" state="time_to_leave">
        >
        Step 3
      </mat-step>
    </mat-horizontal-stepper>
  </mat-expansion-panel>

  <!--Tables-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Tables
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="table-container">
      <table [dataSource]="dataSource" mat-table class="table" matSort>
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> Quote #</th>
          <td mat-cell *matCellDef="let element"> {{element.position}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Insured Name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef> Created Date</th>
          <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef> Modified Date</th>
          <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>
    <mat-paginator [length]="5" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
    </mat-paginator>
  </mat-expansion-panel>

  <!--Modals-->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Modals
      </mat-panel-title>
    </mat-expansion-panel-header>
    <button mat-raised-button (click)="openDialog()">Open Modal</button>
  </mat-expansion-panel>
</mat-card>
