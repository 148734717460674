import { RoleEnum } from '../auth/dtos';
import * as i0 from "@angular/core";
var PermissionManagerService = /** @class */ (function () {
    function PermissionManagerService() {
        this.userKey = "user";
    }
    PermissionManagerService.prototype.setCurrentUser = function (user) {
        localStorage.setItem(this.userKey, JSON.stringify(user));
    };
    PermissionManagerService.prototype.getCurrentUser = function () {
        return JSON.parse(localStorage.getItem(this.userKey));
    };
    PermissionManagerService.prototype.getCurrentToken = function () {
        return this.getCurrentUser().Token;
    };
    PermissionManagerService.prototype.hasAdminPermission = function () {
        return this.getCurrentUser().Role === RoleEnum.PERSONABLE;
    };
    PermissionManagerService.prototype.hasAgencyPermission = function () {
        var userRole = this.getCurrentUser().Role;
        return userRole === RoleEnum.AGENCY_ADMIN || userRole == RoleEnum.USER;
    };
    PermissionManagerService.prototype.hasAgencyGroupPermission = function () {
        return this.getCurrentUser().Role === RoleEnum.AGENCY_GROUP_ADMIN;
    };
    PermissionManagerService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionManagerService_Factory() { return new PermissionManagerService(); }, token: PermissionManagerService, providedIn: "root" });
    return PermissionManagerService;
}());
export { PermissionManagerService };
