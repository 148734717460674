import { Component } from '@angular/core';
import { FrameworkConfigService, FrameworkConfigSettings } from 'src/fw/services/framework-config.service';
import { MenuService } from 'src/fw/services/menu.service';
import { menus } from '../app.menu';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {

  constructor(private frameworkConfigService: FrameworkConfigService,
    private menuService: MenuService) {
    let config: FrameworkConfigSettings = {
      showLanguagesSelector: false,
      showStatusBar: true,
      showStatusBarBreakpoint: 800,
      showUserControls: true,
      socialIcons: []
    }

    this.frameworkConfigService.configure(config);
    this.menuService.items = menus;

  }

}
