import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var HttpOptions = /** @class */ (function () {
    function HttpOptions() {
    }
    HttpOptions.prototype.getDefault = function () {
        return httpOptions;
    };
    HttpOptions.ngInjectableDef = i0.defineInjectable({ factory: function HttpOptions_Factory() { return new HttpOptions(); }, token: HttpOptions, providedIn: "root" });
    return HttpOptions;
}());
export { HttpOptions };
