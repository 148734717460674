import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VisitorsService = /** @class */ (function () {
    function VisitorsService(http) {
        this.http = http;
    }
    VisitorsService.prototype.getIpAddress = function () {
        return this.http
            .get('https://api.ipify.org/?format=json')
            .pipe(catchError(this.handleError));
    };
    VisitorsService.prototype.handleError = function (error) {
        return throwError(error.message);
    };
    VisitorsService.ngInjectableDef = i0.defineInjectable({ factory: function VisitorsService_Factory() { return new VisitorsService(i0.inject(i1.HttpClient)); }, token: VisitorsService, providedIn: "root" });
    return VisitorsService;
}());
export { VisitorsService };
