import { HttpOptions } from './HttpOptions';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ResponseDto } from '../response-dto';
import { CredentialsService } from './credentials.service';
import { NotifierService } from 'angular-notifier';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./HttpOptions";
import * as i3 from "angular-notifier";
import * as i4 from "./credentials.service";
var InternalAPI = /** @class */ (function () {
    function InternalAPI(http, httpOps, notifierService, credentialsService) {
        var _this = this;
        this.http = http;
        this.httpOps = httpOps;
        this.notifierService = notifierService;
        this.credentialsService = credentialsService;
        this.genericServiceUrl = 'ui/GenericService';
        this.genericServiceHttpResponseUrl = 'ui/GenericServiceHttpResponse';
        this.genericMessage = function (message, isError) {
            if (message && message.length) {
                if (isError) {
                    _this.notifierService.notify('error', message);
                }
                else {
                    if (message !== '(R-1803) Invalid term entered. Semi-Annual rates shown.') {
                        _this.notifierService.notify('warning', message);
                    }
                }
            }
        };
        this.checkStatusHttp = function (t, onError) {
            if (t.StatusCode !== 200) {
                _this.genericMessage(t.Message, true);
            }
            if (t.StatusCode !== 200 && onError) {
                onError();
            }
        };
        this.checkStatus = function (t, onError) {
            _this.genericMessage(t.ErrorMessage, true);
            _this.genericMessage(t.WarningMessage, false);
            if (!t.Sucess && t.DiamondErrors && t.DiamondErrors.length) {
                t.DiamondErrors.forEach(function (element) {
                    _this.genericMessage(element.replace(/<[^>]*>/g, ''), true);
                });
            }
            if (!t.Sucess && onError != null) {
                onError();
            }
            if (t.Sucess && t.Records) {
                // The HasWarnigErrors property is badly type because comes from back-end in that way
                // the correct name is HasWarningErrors with ning
                if (t.Records.length > 0 && t.Records[0] && (t.Records[0].HasErrors || t.Records[0].HasWarnigErrors)) {
                    if (t.Records[0].DiamondErrors && t.Records[0].DiamondErrors.length) {
                        t.Records[0].DiamondErrors.forEach(function (element) {
                            _this.genericMessage(element.replace(/<[^>]*>/g, '\n'), true);
                        });
                    }
                    if (t.Records[0].DiamondWarnings && t.Records[0].DiamondWarnings.length) {
                        t.Records[0].DiamondWarnings.forEach(function (element) {
                            _this.genericMessage(element.replace(/<[^>]*>/g, '\n'), false);
                        });
                    }
                }
            }
        };
    }
    InternalAPI.prototype.getAuthorizationHeader = function () {
        var token = this.credentialsService.GetAuthData();
        var headers = token ? { authorization: "Basic " + token } : {};
        return headers;
    };
    InternalAPI.prototype.post = function (url, body, onError) {
        var _this = this;
        if (onError === void 0) { onError = null; }
        if (body === null) {
            body = {};
        }
        var request = {
            body: JSON.stringify(body),
            httpMethod: 'POST',
            uri: url,
            headers: this.getAuthorizationHeader()
        };
        return this.http.post(this.genericServiceUrl, request, this.httpOps.getDefault())
            .pipe(tap(function (t) {
            _this.checkStatus(t, onError);
        }));
    };
    InternalAPI.prototype.get = function (url, onError) {
        var _this = this;
        if (onError === void 0) { onError = null; }
        var request = {
            body: null,
            httpMethod: 'GET',
            uri: url,
            headers: this.getAuthorizationHeader()
        };
        return this.http.post(this.genericServiceUrl, request, this.httpOps.getDefault())
            .pipe(tap(function (t) {
            _this.checkStatus(t, onError);
        }));
    };
    InternalAPI.prototype.httpResponse = function (url, onError, body) {
        var _this = this;
        if (onError === void 0) { onError = null; }
        var request = {
            body: body ? JSON.stringify(body) : null,
            httpMethod: body ? 'POST' : 'GET',
            uri: url,
            headers: this.getAuthorizationHeader()
        };
        return this.http.post(this.genericServiceHttpResponseUrl, request)
            .pipe(tap(function (t) {
            _this.checkStatusHttp(t, onError);
        }));
    };
    InternalAPI.prototype.directPost = function (url, body, onError) {
        var _this = this;
        if (onError === void 0) { onError = null; }
        if (body === null) {
            body = {};
        }
        return this.http.post(url, body, this.httpOps.getDefault())
            .pipe(tap(function (t) {
            _this.checkStatus(t, onError);
        }));
    };
    InternalAPI.prototype.directGet = function (url, onError) {
        var _this = this;
        if (onError === void 0) { onError = null; }
        return this.http.post(url, this.httpOps.getDefault())
            .pipe(tap(function (t) {
            _this.checkStatus(t, onError);
        }));
    };
    InternalAPI.ngInjectableDef = i0.defineInjectable({ factory: function InternalAPI_Factory() { return new InternalAPI(i0.inject(i1.HttpClient), i0.inject(i2.HttpOptions), i0.inject(i3.NotifierService), i0.inject(i4.CredentialsService)); }, token: InternalAPI, providedIn: "root" });
    return InternalAPI;
}());
export { InternalAPI };
