export interface LoginRequest {
    Name: string;
    Password: string;
    BridgeQuote: boolean;
    BridgeQuoteNumber?: any;
}

export interface LoginDomianRequest {
    Name: string;
    LoginDomain: string;
}

export interface User {
    UserId: number;
    DisplayName: string;
    Email: string;
    Token?: any;
    IsDiamondAuthicated: boolean;
    IsImpersonate: boolean;
    Authorities: Authority[];
    Role: number;
    AgencyInformation: AgencyInformation;
    GoToBridgeQuote: boolean;
    BridgeQuoteNumber?: any;
    ProducerName: string;
    Producer?: any;
    CanLogin: boolean;
    PasswordMustBeChanged: boolean;
}

export interface AgencyInformation {
    Id: number;
    Name: string;
    Address: string;
    Email: string;
    PhoneNumber: string;
    Code: string;
    AgencyId: number;
    AgencyGroupId: number;
    AgencyProducers: any[];
    StateId: number;
    StateName: string;
    CompanyId: number;
    CompanyName: string;
    LobId: number;
    VersionId: number;
    AgencyLobs: AgencyLob[];
    Configuration: Configuration;
    AgencyStatus?: any;
    BillMethodId: number
}

export interface Configuration {
    CanRunCLUE: boolean;
    CanRunMVR: boolean;
    CanRunSAQ: boolean;
    CanWriteNB: boolean;
    CompanyStateLobId: number;
    Id: number;
    IsActive: boolean;
    Name: string;
    QuoteMaxVehicles: number;
    Status: number;
    EnableRoadsideAssistance: boolean;
    EnableMexicoCoverage: boolean;
    CanWetSign: boolean;
    CanEsign: boolean;
    RemoveMvrManualAV: boolean;
    CanNbEffectiveTomorrow: boolean;
    CanRwEffectiveTomorrow: boolean;
    CanIssueCollisionAndComprehensive: boolean;
    EnablePercentagePayment: number;
}

export interface AgencyLob {
    AgencyId: number;
    CompanyId: number;
    CompanyName: string;
    LobId: number;
    LobName: string;
    StateId: number;
    StateName: string;
    VersionId: number;
    UserId: number;
    IsSuspended: boolean;
    CanWriteNB: boolean;
}

export interface Authority {
    Amount: number;
    AuthorityGroupId: number;
    AuthorityGroupName: string;
    Definition: string;
    Description: string;
    UserId: number;
    DisplayDescription?: string;
    Display: boolean;
    Id: number;
    Enabled: boolean;
    Status: boolean;
    IsNew: boolean;
    DetailStatusCode: number;
    ForSave: boolean;
    ForDelete: boolean;
    AddedDate: string;
    PcAddedDate: string;
    LastModifiedDate: string;
}


export interface Role {
    bitmask: number;
    title: string;
}

export enum RoleEnum {
    PERSONABLE = 5,
    AGENCY_GROUP_ADMIN = 4,
    AGENCY_ADMIN = 3,
    USER_MANAGER = 2,
    USER = 1,
    NONE = 0
}