import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { UserApi } from '../user-api';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from "../../../app/services/auth/auth.service";
var SignInComponent = /** @class */ (function () {
    function SignInComponent(userApi, router, fb, as) {
        this.userApi = userApi;
        this.router = router;
        this.as = as;
        this.hide = true;
        this.fg = fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            system: ['42']
        });
    }
    SignInComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.as.getSystems().subscribe(function (res) { return _this.systems = res; });
    };
    SignInComponent.prototype.clearMessage = function () {
        this.message = "";
    };
    SignInComponent.prototype.onSubmit = function (fg) {
        var _this = this;
        if (fg.valid) {
            this.userApi.signIn(fg.value.username, fg.value.password, fg.value.system, false).subscribe(function (authorized) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    if (authorized) {
                        this.message = "";
                        if (this.userApi.isNewsAvailable()) {
                            this.router.navigate(['/backoffice/news']);
                        }
                        this.router.navigate(['/backoffice/policysearch']);
                        localStorage.setItem('p', fg.value.password);
                    }
                    else {
                        this.message = "Authentication Failure";
                    }
                    return [2 /*return*/];
                });
            }); });
        }
    };
    SignInComponent.prototype.isLoading = function () {
        return this.userApi.isLoading();
    };
    SignInComponent.prototype.goToDomain = function () {
        this.router.navigate(['domain-login']);
    };
    SignInComponent.prototype.delay = function (ms) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return setTimeout(resolve, ms); })];
            });
        });
    };
    return SignInComponent;
}());
export { SignInComponent };
