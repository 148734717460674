import { InternalAPI } from './client-api';
import { of, Subject } from 'rxjs';
import { SystemVersionDataRequest } from '../models/SystemVersionDataRequest';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./client-api";
import * as i2 from "@angular/common/http";
var VersionDataService = /** @class */ (function () {
    function VersionDataService(internalApi, http) {
        this.internalApi = internalApi;
        this.http = http;
        this.versionURL = "UI/APIVersionData";
        this.systemURL = "UI/APISystemData";
        this.url = "Quote/GetVersionData/{userId}/{versionId}";
        this.vdSubject = new Subject();
    }
    VersionDataService.prototype.loadSystemData = function (environment) {
        var systemJson = localStorage.getItem("systemData");
        var systemData = JSON.parse(systemJson);
        if (systemData) {
            return of(systemData);
        }
        var systemDataRequest = new SystemVersionDataRequest();
        systemDataRequest.Environment = environment;
        return this.http.post(this.systemURL, systemDataRequest)
            .pipe(map(function (userResponse) {
            if (userResponse.ResponseStatus.StatusCode === 200) {
                localStorage.setItem("systemData", JSON.stringify(userResponse));
                return userResponse;
            }
            else {
                return of(null);
            }
        }));
    };
    VersionDataService.prototype.loadVersionData = function (versionId) {
        var versionJson = localStorage.getItem("versionId" + versionId);
        var versionData = JSON.parse(versionJson);
        if (versionData) {
            return of(versionData);
        }
        var creds = localStorage.getItem("user");
        var loginResponse = JSON.parse(creds);
        var versionDataRequest = new SystemVersionDataRequest();
        versionDataRequest.Environment = localStorage.getItem("environment");
        versionDataRequest.Token = loginResponse.Token;
        versionDataRequest.UserName = "ServiceAccount@bluefireins.com";
        versionDataRequest.UserId = loginResponse.DisplayName;
        versionDataRequest.Password = localStorage.getItem("p");
        versionDataRequest.VersionId = versionId;
        return this.http.post(this.versionURL, versionDataRequest)
            .pipe(map(function (userResponse) {
            if (userResponse.ResponseStatus.StatusCode === 200) {
                localStorage.setItem("versionId" + versionId, JSON.stringify(userResponse));
                return userResponse;
            }
            else {
                return of(null);
            }
        }));
    };
    VersionDataService.prototype.getVersionDataId = function () {
        return this.getVersionData().VersionId;
    };
    VersionDataService.prototype.getVersionData = function () {
        return JSON.parse(localStorage.getItem('versionData'));
    };
    VersionDataService.prototype.subscribe = function (handler) {
        return this.vdSubject.subscribe(handler);
    };
    VersionDataService.ngInjectableDef = i0.defineInjectable({ factory: function VersionDataService_Factory() { return new VersionDataService(i0.inject(i1.InternalAPI), i0.inject(i2.HttpClient)); }, token: VersionDataService, providedIn: "root" });
    return VersionDataService;
}());
export { VersionDataService };
