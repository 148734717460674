import { Injectable } from '@angular/core';
import { User, RoleEnum } from '../auth/dtos';

@Injectable({
    providedIn: 'root'
})
export class PermissionManagerService {

    private userKey = "user";

    public setCurrentUser(user: User): void {
        localStorage.setItem(this.userKey, JSON.stringify(user));
    }

    public getCurrentUser(): User {
        return JSON.parse(localStorage.getItem(this.userKey));
    }

    public getCurrentToken(): string {
        return this.getCurrentUser().Token;
    }

    public hasAdminPermission(): boolean {
        return this.getCurrentUser().Role === RoleEnum.PERSONABLE;
    }

    public hasAgencyPermission(): boolean {
        let userRole = this.getCurrentUser().Role;
        return userRole === RoleEnum.AGENCY_ADMIN || userRole == RoleEnum.USER;
    }

    public hasAgencyGroupPermission(): boolean {
        return this.getCurrentUser().Role === RoleEnum.AGENCY_GROUP_ADMIN;
    }

}
