import { PolicyImage } from "../dtos/diamond-policy-image-dto";

export class SystemVersionDataRequest {
  UserId: string;
  UserName: string;
  Password: string;
  Token: string;
  IsTest: boolean;
  Environment: string;
  VersionId: number;
}
