<h2 class="title">Modal</h2>
<mat-dialog-content>
  <h3 class="title">Form Example</h3>
  <mat-divider>
  </mat-divider>

  <br>
  <br>

  <mat-form-field>
    <input placeholder="Date of Birth" matInput [matDatepicker]="picker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button >Cancel</button>
  <button mat-raised-button class="mat-primary-extended">Save</button>
</mat-dialog-actions>
