import * as i0 from "@angular/core";
var CredentialsService = /** @class */ (function () {
    function CredentialsService() {
        this.authDataKey = "authData";
    }
    CredentialsService.prototype.SetAuthData = function (credentials) {
        var authData = this.EncodeToBase64(credentials.Name + ':' + credentials.Password + ':' + 'undefined');
        localStorage.setItem(this.authDataKey, JSON.stringify(authData));
    };
    CredentialsService.prototype.GetAuthData = function () {
        return JSON.parse(localStorage.getItem(this.authDataKey));
    };
    CredentialsService.prototype.ClearHeaderLocalStorage = function () {
        localStorage.removeItem(this.authDataKey);
    };
    CredentialsService.prototype.EncodeToBase64 = function (input) {
        var keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        var output = "";
        var chr1 = "";
        var chr2 = "";
        var chr3 = "";
        var enc1 = "";
        var enc2 = "";
        var enc3 = "";
        var enc4 = "";
        var i = 0;
        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            }
            else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output +
                keyStr.charAt(enc1) +
                keyStr.charAt(enc2) +
                keyStr.charAt(enc3) +
                keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);
        return output;
    };
    CredentialsService.ngInjectableDef = i0.defineInjectable({ factory: function CredentialsService_Factory() { return new CredentialsService(); }, token: CredentialsService, providedIn: "root" });
    return CredentialsService;
}());
export { CredentialsService };
