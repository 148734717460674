import { MenuItem } from 'src/fw/services/menu.service';

const preRoute = "/backoffice";

export let menus: Array<MenuItem> = [{
    text: "Policy Servicing",
    icon: "search",
    route: preRoute + "/policysearch",
    submenu: null
  },
  {
    text: "New Quote",
    icon: "playlist_add_check",
    route: preRoute + "/quotes/quote-capture/0",
    submenu: null
},
{
    text: "Reports",
    icon: "insert_chart_outlined",
    route: preRoute + "/reports",
    submenu: null
},
{
    text: "Forms",
    icon: "assignment",
    route: preRoute + "/forms",
    submenu: null
}]
