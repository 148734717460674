import { Component, OnInit } from '@angular/core';
import { UserApi } from '../user-api';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from "../../../app/services/auth/auth.service";

@Component({
  selector: 'fw-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  hide = true;
  fg: FormGroup;
  message: string;
  systems: any;
  constructor(private userApi: UserApi, private router: Router,
     fb: FormBuilder, private as: AuthService) {
    this.fg = fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      system: ['42']
    });
  }

  ngOnInit(): void {
    this.as.getSystems().subscribe(res => this.systems = res);
  }

  clearMessage() {
    this.message = "";
  }

  onSubmit(fg: FormGroup) {
    if (fg.valid) {
      this.userApi.signIn(
        fg.value.username,
        fg.value.password,
        fg.value.system,
        false
      ).subscribe(async authorized => {
        if (authorized) {
          this.message = "";
          if (this.userApi.isNewsAvailable()) {
            this.router.navigate(['/backoffice/news']);
          }
          this.router.navigate(['/backoffice/policysearch']);
          localStorage.setItem('p', fg.value.password);
        } else {
          this.message = "Authentication Failure";
        }
      });
    }
  }

  isLoading() {
    return this.userApi.isLoading();
  }

  goToDomain() {
    this.router.navigate(['domain-login']);
  }
  async delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}

