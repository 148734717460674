import { UserApi } from 'src/fw/users/user-api';
import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InternalAPI } from '../utils/client-api';
import { LoginRequest, User } from './dtos';
import { LoginResponseDto, ResponseDto } from '../response-dto';
import { VersionDataService } from '../utils/version-data.service';
import { PermissionManagerService } from '../utils/permission-manager.service';
import { NewService } from 'src/app/backoffice/news/services/new.service';
import { CredentialsService } from '../utils/credentials.service';
import { GenericRequest } from '../utils/GenericRequest';
import { HttpClient } from '@angular/common/http';
import { HttpOptions } from '../utils/HttpOptions';
import { VersionData } from '../dtos/version-data-dto';
import { NotifierService } from 'angular-notifier';
import { PortalAuthService } from "./portal-auth.service";
import { PSRequest } from "../../backoffice/policysearch/models/Request";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements UserApi {

  url = "authentication";
  autoBridgingUrl = "bridging/GetCredentials/";
  domainUrl = "DomainAuthenticate";
  loginServiceUrl = 'login/Login';
  autoBridgingServiceUrl = 'UI/AutoBridge';
  PSRequest: PSRequest = new PSRequest();
  loading = false;
  isLoading() {
    return this.loading;
  }

  isAuthenticated() {
    let authData = localStorage.getItem("authData");
    if (authData && authData.length > 0) {
      return true;
    }
    return false;
  }

  constructor(private router: Router,
    private internalApi: InternalAPI,
    private versionDataService: VersionDataService,
    private permissionManager: PermissionManagerService,
    private newService: NewService,
    private credentialsService: CredentialsService,
    private notifierService: NotifierService,
    private http: HttpClient,
    private httpOps: HttpOptions,
    private portalAuthService: PortalAuthService) {
  }

  getSystems(): Observable<GetSystemsList> {
    return this.http.get<GetSystemsList>("UI/APIGetSystemsList")
      .pipe(map(userResponse => {
        return userResponse;
      }));
  }

  signIn(username: string, password: string, system: string, rememberMe: boolean): Observable<boolean> {
    this.loading = true;
    this.clearLocalStorage();
    this.credentialsService.ClearHeaderLocalStorage();

    let body: LoginRequest = {
      Name: username,
      Password: password,
      BridgeQuote: null,
      BridgeQuoteNumber: null
    };

    let onError = () => { this.loading = false };

    this.PSRequest.UserName = username;
    this.PSRequest.UserId = username;
    this.PSRequest.Password = password;
    this.PSRequest.Environment = "C" + system;

    return forkJoin(
      this.versionDataService.loadSystemData(this.PSRequest.Environment),
      this.portalAuthService.auth<any>(this.PSRequest)
    ).pipe(map(([sysData, auth]) => {
      this.loginSuccess(auth, body);
      return auth.ResponseStatus.StatusCode === 200;
    }));
  }

  signout() {
    this.clearLocalStorage();
    this.loading = false;
    this.router.navigate(["/sign-in"]);
    return of({});
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  loginSuccess(r: any, credentials) {
    if (r.ResponseStatus.StatusCode === 200) {
      if (r.SystemVersion.ErrorMessage !== '') {
        localStorage.setItem('VersionNumber', '0');
      } else {
        localStorage.setItem('VersionNumber', r.SystemVersion.VersionNumber);
      }
      localStorage.setItem('BillMethodId', r.BillMethodId);
      r.DisplayName = r.UserName;
      r.UserId = r.DiamondUserId;
      this.permissionManager.setCurrentUser(r);
      this.credentialsService.SetAuthData(credentials);
    } else {
      this.loading = false;
    }
  }

  isNewsAvailable() {
    let onError = () => { this.loading = false };

    this.newService.getNewsByAgency(onError).subscribe(res => {
      if (res && res.length !== 0) {
        return true;
      }
    });

    return false;
  }

  public getVersionNumber(): string {
    return localStorage.getItem('VersionNumber');
  }

  autoBridge(policynumber: string): Observable<boolean> {
    this.credentialsService.ClearHeaderLocalStorage();
    let onError = () => { this.loading = false };
    const tempEnvironment = "C42"

    const autoBridge = this.http.post<any>(this.autoBridgingServiceUrl, { Token: policynumber, Environment: tempEnvironment }).pipe(map(userResponse => {
      // login successful if there's a jwt token in the response
      if (userResponse.ResponseStatus.StatusCode === 200) {
        localStorage.setItem('portalAuth', JSON.stringify(userResponse));
        localStorage.setItem('environment', tempEnvironment);
      }
      return userResponse;
    }));

    return forkJoin(
      this.versionDataService.loadSystemData(tempEnvironment),
      autoBridge
    )
      .pipe(
        map(([sysData, auth]) => {
          let body: LoginRequest = {
            Name: auth.UserName,
            Password: "",
            BridgeQuote: null,
            BridgeQuoteNumber: null
          };
          this.loginSuccess(auth, body)
          window.location.href += 'backoffice/quotes/quote-capture/' + policynumber + '/bridge';
          return true
        })
      );
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public getBridgingCredentials<T>(url: string, onError: () => void = null): Observable<T> {
    const request: GenericRequest = {
      body: null,
      httpMethod: "GET",
      uri: url,
      headers: this.internalApi.getAuthorizationHeader()
    };

    return this.http.post<T>(this.autoBridgingServiceUrl, request)
      .pipe();
  }

  isAutoBridged() {
    let autoBridgeData = localStorage.getItem("AutoBridged");
    if (autoBridgeData && autoBridgeData == "1") {
      return true;
    }
    return false;
  }
}
export class GetSystemsList {
  Value: string;
  Description: string;
}
