import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class VisitorsService {


    constructor(private http: HttpClient) { }

    getIpAddress() {
        return this.http
            .get('https://api.ipify.org/?format=json')
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        return throwError(error.message);
    }
}
