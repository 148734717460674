/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register-user.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/router";
import * as i4 from "./register-user.component";
var styles_RegisterUserComponent = [i0.styles];
var RenderType_RegisterUserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterUserComponent, data: {} });
export { RenderType_RegisterUserComponent as RenderType_RegisterUserComponent };
export function View_RegisterUserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "register-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["height", "60px"], ["src", "scripts/assets/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Register for New Account"])), (_l()(), i1.ɵeld(4, 0, null, null, 20, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(6, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "input", [["class", "form-control"], ["name", "username"], ["placeholder", "Requested User Name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "input", [["class", "form-control"], ["name", "email"], ["placeholder", "Email"], ["type", "email"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "input", [["class", "form-control"], ["name", "password"], ["placeholder", "Password"], ["type", "password"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "input", [["class", "form-control"], ["name", "password-again"], ["placeholder", "Password again"], ["type", "password"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 5, "button", [["class", "btn btn-default"], ["routerLink", "/sign-in"], ["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 16384, [[1, 4]], 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(19, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Register"]))], function (_ck, _v) { var currVal_7 = "/sign-in"; _ck(_v, 18, 0, currVal_7); var currVal_8 = "active"; _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_RegisterUserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fw-register-user", [], null, null, null, View_RegisterUserComponent_0, RenderType_RegisterUserComponent)), i1.ɵdid(1, 114688, null, 0, i4.RegisterUserComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterUserComponentNgFactory = i1.ɵccf("fw-register-user", i4.RegisterUserComponent, View_RegisterUserComponent_Host_0, {}, {}, []);
export { RegisterUserComponentNgFactory as RegisterUserComponentNgFactory };
